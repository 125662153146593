<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          
          <div class="col-md-12 pull-left">
            <small class="text-muted">Name</small>
            <h6>{{ state.detail.name === "" ? '-' : state.detail.name }}</h6>
            <small class="text-muxed">Permissions</small>
            <h6 v-html="allPermissions"></h6>
        </div>
      </div>
      <hr>
    </div>
  </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
// import ImageItem from "../../../components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    // ImageItem
  },
  computed: {
    allPermissions(){
      let text = "";
      if(this.$store.state.groups.detail.permission.length){
        this.$store.state.groups.detail.permission.forEach((perm)=>{
          let menu = perm.split('.');
          text += (this.$store.state.groups.lang[menu[0]] + " : " + menu[1].toUpperCase() + "<br/><br/>");
        })
      }
      return text;
    },
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.groups;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("groups/STATE", state);
      this.$store.dispatch("groups/getGroupsById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("groups/onCancel");
    },
  }
};
</script>
